import { Container } from "react-bootstrap";
import "./Footer.css";

export default function Footer() {
  return (
    <div fluid={true} className="footer">
      <h5 className="FooterText">
        © 2022 by <a href='https://www.atomicloops.com/'>Atomic Loops</a>. Proudly created in India 
      </h5>
    </div>
  );
}

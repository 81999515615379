import "./PromotionSection.css";

import Order_Online from "./OrderOnline.jpg";
import Baker from "./Baker.jpeg";
import Customize_Cake from "./CustomizeCake.jpg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function PromotionSection() {
  return (
    <>
      <h1 style={{ textAlign: "center", margin: "3%" }} className="PromHeader">Check Us Out!</h1>
      <Container
        fluid
        className="box "
        style={{
          margin: "0",
          padding: "0",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <Row style={{ margin: "0", padding: "0" }}>
          <Col style={{ margin: "0", padding: "0" }} md={4} sm={6}>
            <img src={Order_Online} className=" PromImg1 PromImg"></img>{" "}
          </Col>
          <Col style={{ margin: "0", padding: "0" }} md={4} sm={6}>
            <img src={Baker} className=" PromImg2 PromImg"></img>
          </Col>
          <Col style={{ margin: "0", padding: "0" }} md={4} sm={12}>
            <img src={Customize_Cake} className=" PromImg3 PromImg"></img>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { Container } from "react-bootstrap";
import "./InstagramComp.css";

import InstaPost1 from "./InstaPost1.jpg";
import InstaPost2 from "./InstaPost2.jpg";
import InstaPost3 from "./InstaPost3.jpg";
import InstaPost4 from "./InstaPost4.jpg";
import InstaPost5 from "./InstaPost5.jpg";
import InstaPost6 from "./InstaPost6.jpg";
import InstaPost7 from "./InstaPost7.jpg";
import InstaPost8 from "./InstaPost8.jpg";
import InstaPost9 from "./InstaPost9.jpg";

import { Row, Col } from "react-bootstrap";

export default function InstagramComp() {
  return (
    <Container fluid className="InstaCompCont">
      <h1 className="InstagramCompHeader">Instagram</h1>
      <div>
        <Row>
          <Col className="Instatest">
            <img src={InstaPost1} className="InstaTest leftside"></img>
          </Col>
          <Col>
            <img src={InstaPost2} className="InstaTest  middleSide"></img>
          </Col>
          <Col>
            <img src={InstaPost3} className="InstaTest  rightside"></img>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={InstaPost4} className="InstaTest leftside"></img>
          </Col>
          <Col>
            <img src={InstaPost5} className="InstaTest middleSide"></img>
          </Col>
          <Col>
            <img src={InstaPost6} className="InstaTest rightside"></img>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={InstaPost7} className="InstaTest leftside"></img>
          </Col>
          <Col>
            <img src={InstaPost8} className="InstaTest middleSide"></img>
          </Col>
          <Col>
            <img src={InstaPost9} className="InstaTest rightside"></img>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

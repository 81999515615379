import "./CakeCarousel.css";

import Card from "react-bootstrap/Card";

import { Carousel } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import CakeCarouselImg1 from "./CakeCarouselImg1.jpeg";
import CakeCarouselImg2 from "./CakeCarouselImg2.jpg";
import CakeCarouselImg3 from "./CakeCarouselImg3.jpg";
import CakeCarouselImg4 from "./CakeCarouselImg4.jpeg";
import CakeCarouselImg5 from "./CakeCarouselImg5.jpeg";
import CakeCarouselImg6 from "./CakeCarouselImg6.jpg";
import CakeCarouselImg7 from "./CakeCarouselImg7.jpg";
import CakeCarouselImg8 from "./CakeCarouselImg8.jpg";
import CakeCarouselImg9 from "./CakeCarouselImg9.jpeg";

export default function CakeCarousel() {
  return (
    <Carousel controls={false} className="test" style={{}}>
      <Carousel.Item className="test">
        <Row>
          <Col sm={4}>
            <Card className="Card">
              <Card.Img variant="top" src={CakeCarouselImg1} />
            </Card>
          </Col>
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg2} />
            </Card>
          </Col>
          {/* <Col>
            <Card style={{ width: "18rem" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg1} />
            </Card>
          </Col> */}
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card RowLastCard">
              <Card.Img variant="top" src={CakeCarouselImg3} />
            </Card>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item className="test">
        <Row>
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg4} />
            </Card>
          </Col>
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg5} />
            </Card>
          </Col>

          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card RowLastCard">
              <Card.Img variant="top" src={CakeCarouselImg6} />
            </Card>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item className="test">
        <Row>
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg7} />
            </Card>
          </Col>
          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card">
              <Card.Img variant="top" src={CakeCarouselImg8} />
            </Card>
          </Col>

          <Col sm={4}>
            <Card style={{ width: "100%" }} className="Card RowLastCard">
              <Card.Img variant="top" src={CakeCarouselImg9} />
            </Card>
          </Col>
        </Row>
      </Carousel.Item>
    </Carousel>
  );
}

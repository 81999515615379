import "./MainPhoto.css";

import MainPicture from "./MainPhoto.webp";
import MainPhoto1 from './MainPhoto1.jpeg'

export default function MainPhoto() {
  return (
    <div className="main_pic_container">
      <img src={MainPicture} className=" main_picture "></img>
    </div>
  );
}

import NavbarComp from "./components/Navbar/NavbarComp";
import MainPhoto from "./components/MainPhoto/MainPhoto";

import Description from "./components/Description/Description";
import PromotionSection from "./components/PromotionSection/PromotionSection";
import CakeCollection from "./Cake Collection/CakeCollection";
import InstagramComp from "./Instagram/InstagramComp";
import About from "./About/About";
import Footer from "./Footer/Footer";

import Contact from "./Contact/Contact";

import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavbarComp />
                <MainPhoto />
                <Description />
                <PromotionSection />
                <CakeCollection />
                <InstagramComp />
                <Footer />
              </>
            }
          />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

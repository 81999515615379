import "./DescriptionText.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function DescriptionText() {
  return (
    <Container fluid="md" className="DescText">
      <Row>
        <Col>
          <h2 className="Main_DescText">
            MY <span style={{ color: "#D3B3B8" }}>CAKES</span> ARE<br></br>
            HANDCRAFTED<br></br>
            WITH LOVE.
          </h2>
          <h5 className="DescText_info">
            Our CAKES Are Handcrafted With Love.At Raj Bakery, we take care of
            all ingredients, baking processes, decorations and customisations
            with utmost attention. We know the importance of celebrations and we
            are happy to be a part of every family’s happiness with a dose of
            sweet and delicious range of cakes. All the cakes are available to
            be customised with different flavours, shapes and designs without
            any extra charges.
          </h5>
        </Col>
      </Row>
    </Container>
  );
}

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Navbar_Brand_img from "./Navbar_brand_img.png";

import "./NavbarComp.css";

// React Icons
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";

function CollapsibleExample() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      sticky="top"
    >
      <Container
        style={{
          marginLeft: "0",
          paddingLeft: "0",
          marginRight: "0",
          width: "100%",
        }}
      >
        <Navbar.Brand href="/">
          <img
            alt=""
            src={Navbar_Brand_img}
            // width="100"
            height="80"
            className="d-inline-block align-top img-responsive brand_logo"
          />{" "}
          {/* Wedding Cakes<br></br> By Brian Hill */}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav " />
        <Navbar.Collapse id="responsive-navbar-nav  ">
          <Nav className="ms-auto  ">
            <Nav.Link href="/about" className="mx-2">
              About
            </Nav.Link>

            <Nav.Link href="/contact" className="mx-2">
              Contact
            </Nav.Link>

            <Nav.Link className="mx-2" href="https://instagram.com/rajbakery.nagpur?igshid=MDE2OWE1N2Q=">
            <BsInstagram size="25" />
            </Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;

import "./About.css";

import NavbarComp from "../components/Navbar/NavbarComp";
import { Container } from "react-bootstrap";

import { Row, Col } from "react-bootstrap";

import AboutImg from "./AboutImg.jpeg";

import Footer from "../Footer/Footer";

export default function About() {
  return (
    <Container fluid>
      <NavbarComp />
      <div className="AboutContianer">
        <h1 className="AboutTitle">
          <span className="AboutPageHeader">ABOUT</span>
        </h1>
        <span>
          <h4 className="AboutWho">Raj Bakery by Mrs. Hemlata Sorte</h4>
        </span>
        <div className="AboutDesc">
          At Raj Bakery, we sell more than cakes. Our personal delivery
          experience, time-honoured recipes and comforting taste have enhanced
          celebrations throughout Nagpur since opening the bakery in 2020...and
          now we are available across the city. Raj bakery was established
          during the pandemic era. I have always been a cooking fanatic and it
          always amazed me how love can be shared in plated, during celebrations
          and special occasions. As a housewife, I did cook a huge variety of
          dishes for my family and it only made me happier.
        </div>
        <Row className="AboutInfo">
          <Col style={{margin:"0",padding:"0"}}>
            <img src={AboutImg} className="AboutImg"></img>
          </Col>
          <Col lg={6}>
            <div className="AboutDetailedDesc">
              <div className="AboutActualDesc">
                {/* I'm a paragraph. Click here to add your own text and edit me.
                It’s easy. Just click “Edit Text” or double click me to add your
                own content and make changes to the font. Feel free to drag and
                drop me anywhere you like on your page. I’m a great place for
                you to tell a story and let your users know a little more about
                you. <br></br>​ This is a great space to write long text about
                your company and your services. You can use this space to go
                into a little more detail about your company. Talk about your
                team and what services you provide. Tell your visitors the story
                of how you came up with the idea for your business and what
                makes you different from your competitors. Make your company
                stand out and show your visitors who you are. */}
                During the pandemic covid-19, I lost my husband. My entire
                family shattered into pieces and it felt like joy in our lives
                was gone. My beloved husband was a very happy and joyous person,
                and we all only wanted to keep his cheerful memories in place.
                As a result of this, my daughters pushed me to start a venture
                of my own, about the thing which I do the best, which will keep
                my spirits up and might help me cherish the memories which we
                have as a family. Today, Raj Bakery has a record of selling over
                300 cakes at a stretch, and I am very happy to be a contributing
                factor to every home’s celebration. At Raj Bakery, we don’t just
                sell cakes, we add to people’s happiness and bring joy to
                memories.
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
}

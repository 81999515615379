import { Container } from "react-bootstrap";
import "./Contact.css";

import { Button } from "react-bootstrap";

import NavbarComp from "../components/Navbar/NavbarComp";
import Footer from "../Footer/Footer";

import { useState } from "react";
import { send } from "emailjs-com";
import {Row, Col} from "react-bootstrap"

import { FaWhatsapp } from 'react-icons/fa'


import React from "react";

export default function Contact() {
  // const [toSend, setToSend] = useState({
  //   from_name: "",
  //   message: "",
  //   useremail: "",
  //   phoneNumber: "",
  // });

  // const handleChange = (e) => {
  //   setToSend({ ...toSend, [e.target.name]: e.target.value });
  // };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   send("service_c8g698r", "template_52cwat9", toSend, "4z7vfJO50bqZRbHxz")
  //     .then((response) => {
  //       console.log("SUCCESS!", response.status, response.text);
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log("FAILED...", err);
  //     });
  // };

  return (
    <Container fluid className="ContactContainer">
      <NavbarComp />
      <div className="ContactActualCont">
        <h1 className="ContactHeading">CONTACT ME</h1>
        <p className="ContactDesc">
          I'm a paragraph. Click here to add your own text and edit me. I’m a
          great place for you to tell a story and let your users know a little
          more about you.
        </p>
        <h5 className="ContactInfo">
          7972202855 |{" "}
          <a href="mailto:email@example.com" className="ContactMailLink">
          kunikasorte123@gmail.com
          </a>
        </h5>
        <h6 className="ContactAddr">
          Pick up is available from Besa, Nagpur
        </h6>
        {/* <div className="ContactForm">
          <Container className="ContactFormInner" fluid>
            <form className="ContactFormInnerBox" onSubmit={onSubmit}>
              <Row>
                <Col lg={6}>
                  {" "}
                  <input
                    type="text"
                    placeholder="Name"
                    style={{ width: "100%" }}
                    className="FormEle"
                    name="from_name"
                    value={toSend.from_name}
                    onChange={handleChange}
                  ></input>
                </Col>
                <Col>
                  <input
                    type="email"
                    placeholder="Email"
                    className="FormEle"
                    style={{ width: "100%" }}
                    name="useremail"
                    value={toSend.useremail}
                    onChange={handleChange}
                  ></input>
                </Col>
              </Row>

              <br></br>
              <input
                type="number"
                placeholder="Phone"
                className="FormEle"
                style={{ width: "100%" }}
                name="phoneNumber"
                value={toSend.phoneNumber}
                onChange={handleChange}
              ></input>
              <br></br>
              <textarea
                placeholder="Message"
                className="FormEle"
                style={{ width: "100%" }}
                rows="5"
                name="message"
                value={toSend.message}
                onChange={handleChange}
              ></textarea>
              <button
                type="submit"
                placeholder="Submit"
                className="FormEle"
                style={{
                  border: "none",
                  background: "transpaent",
                  textAlign: "center",
                }}
              >
                Submit
              </button>
            </form>
          </Container>
          <div className="dummy">&nbsp;</div>
        </div> */}
         <div className="text-center btn-lg ">
         <Button href="https://wa.me/qr/DB7HQFOMSFUEI1" className="WhatsappButton"  variant="light"><FaWhatsapp size={40} style={{marginRight:"10px"}}/>Connect with us on whatsapp </Button>
          </div>
      </div>

      <Footer />
    </Container>
  );
}

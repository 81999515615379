import { Container } from "react-bootstrap";
import "./DescriptionImg.css";

import "./DescriptionImg.css";

import DescriptionImage from "./DescriptionImg.jpeg";

export default function DescriptionImg() {
  return (
    <div className="Desc_Container" style={{}}>
      <img src={DescriptionImage} className=" DescImg"></img>
    </div>
  );
}
